.App {
  text-align: center;
  padding: 1% 16% 0 16%;
  max-width: 1920px;  /* 최대 너비를 1920px로 설정 */
  height: auto;       /* 자동 높이 조정 */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.active {
  font-weight: bold;  /* 굵은 글씨 */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
ul {
  list-style-type: none;
  display: flex;                /* Flexbox로 설정 */
  justify-content: space-between;  /* 양쪽 정렬 */
  list-style: none;
}

a:visited { color:#000000; text-decoration: none;}

ul li {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  position: "relative";
  /* margin: 1%; */
  margin-left: 1%;
  margin-right: 1%;
}

li {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: auto;
  margin-bottom: auto;
}

.btn-header {
  font-family: "Pretendard Light";
  src: url("../assets/font/Pretendard-1.3.9/web/static/woff2/Pretendard-Light.woff2") format("woff2"),
      url("../assets/font/Pretendard-1.3.9/web/static/woff/Pretendard-Light.woff") format("woff");  
}

.btn-header:hover {
  font-family: "Pretendard Medium";
  cursor: "pointer";
}

.btn-header:after {
  display:block;
  content: '';
  border-bottom: solid 2px #00AF50;  
  transform: scaleX(0);  
  transition: transform 0.3s;
}

.btn-header:hover:after { transform: scaleX(1); }

p {
  font-family: "Pretendard Regular";
  src: url("../assets/font/Pretendard-1.3.9/web/static/woff2/Pretendard-Regular.woff2") format("woff2"),
      url("../assets/font/Pretendard-1.3.9/web/static/woff/Pretendard-Regular.woff") format("woff");
}

.sub-title {
  margin-bottom: 0;
  color: #00AF50;
  font-family: "Pretendard Light";
  src: url("../assets/font/Pretendard-1.3.9/web/static/woff2/Pretendard-Light.woff2") format("woff2"),
      url("../assets/font/Pretendard-1.3.9/web/static/woff/Pretendard-Light.woff") format("woff");
}

.main-title {
  margin-top: 0;
  font-family: "Pretendard ExtraLight";
  src: url("../assets/font/Pretendard-1.3.9/web/static/woff2/Pretendard-ExtraLight.woff2") format("woff2"),
      url("../assets/font/Pretendard-1.3.9/web/static/woff/Pretendard-ExtraLight.woff") format("woff");
}

.main-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
}

.banner1, .banner2, .banner3, .banner4 {
  flex: 1 1 calc(50% - 15px);
  padding-top: 56.25%; /* 16:9 비율 유지 */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 30px; /* 아래 간격을 30px로 설정 */
}

.banner1 {
  background-image: url('../assets/img/Frame8.png');
}

.banner2 {
  background-image: url('../assets/img/Section2.png');
}

.banner3 {
  background-image: url('../assets/img/Section3.png');
}

.banner4 {
  background-image: url('../assets/img/Section4.png');
}


.banner1 {
  background-image: url('../assets/img/Frame8.png');
}

.banner2 {
  background-image: url('../assets/img/Section2.png');
}

.banner3 {
  background-image: url('../assets/img/Section3.png');
}

.banner4 {
  background-image: url('../assets/img/Section4.png');
}



u:not(.underline) {
  /* text-decoration: underline 8px #00AF50; */
  text-decoration: none;
  display: inline;
  box-shadow: inset 0 -9px 0 #00AF50;
}

.logo1 {
  width: 150px;
  height: 50px;
  background-image: url(../assets/logo/svg/logo_horiz_small_kr.svg);
  background-repeat :no-repeat;
  background-size: contain;
  position: relative;
  display : inline-block;
}

.login-page {
  padding: 10% 5% 1% 5%;
  font-family: "Pretendard SemiBold";
  src: url("../assets/font/Pretendard-1.3.9/web/static/woff2/Pretendard-SemiBold.woff2") format("woff2"),
      url("../assets/font/Pretendard-1.3.9/web/static/woff/Pretendard-SemiBold.woff") format("woff");
}

.signup-page {
  padding: 10% 5% 1% 5%;
  font-family: "Pretendard SemiBold";
  src: url("../assets/font/Pretendard-1.3.9/web/static/woff2/Pretendard-SemiBold.woff2") format("woff2"),
      url("../assets/font/Pretendard-1.3.9/web/static/woff/Pretendard-SemiBold.woff") format("woff");
}

.login-logo {
  width: 180px;
  height: 60px;
  background-image: url(../assets/logo/svg/logo_horiz_small_kr.svg);
  background-repeat :no-repeat;
  background-size: contain;
  position: relative;
  margin: auto;
  display : block;
  margin-bottom: 10%;
}

.footer-logo {
  width: 100%;
  aspect-ratio: 16 / 4;
  background-image: url(../assets/img/Bottom.png);
  background-size: contain;
  background-repeat :no-repeat;
}

/* primary button */
.p-button {
  background-color: #00AF50;
  border-bottom-color: #00AF50;
  color: #ffffff;
  width: 100%;
  height: 10%;
  margin-bottom: 3%;
  border-radius: '10px'; 
  border-width: '2px';
}

.p-button:hover {
  background-color: #9ed4b6;
  color: #000000;
}

/* secondary button */
.s-button {
  background-color: #ffffff;
  border-bottom-color: #ffffff;
  color: #000000;
  width: 100%;
  height: 10%;
  margin-bottom: 3%;
  border-radius: '10px'; 
  border-width: '2px';
}

.s-button:hover {
  background-color: #bbbbbb;
  color: #ffffff;
}

.side-bar {
  background: rgba(173, 181, 189, 0.50);
  backdrop-filter: blur(2.800000190734863px);
}

.modal-body {
  height: 200px;
  text-align: center;
  padding: 10% 1% 10% 1%;
}

.date-form {
  z-index: 9999 !important;
}

.date-picker {
  display: flex;
  height: 58px;
  width: 330px;
  border-radius: 6px;
  margin-bottom: 4%;
  padding: 3%;
  border: 0px;  
}

.p-button-sm {
  background-color: #00AF50;
  border-bottom-color: #00AF50;
  border: 0px;
  color: #ffffff;
  width: 30%;
  height: 10%;
  margin-bottom: 3%;
}

.p-button-sm:hover {
  background-color: #9ed4b6;
  color: #000000;
}

.profile-badge {
  width: 35px;
  height: 35px;
  border-radius: 30px;
  text-align: center;
  padding-top: 15%;
}

.reserve-form {
  border: 2px solid;
  border-radius: 3px;
  width: 175px;
  height: 35px;
  margin-right: 2%;
  text-align: center;
}

.mypage-entry-form {
  width: 40%;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  position: "relative";
  margin: 2%
}

.my-page {
  /* display: inline-block;
  vertical-align: middle;
  text-align: center; */
  width: 120%;
  margin-top: 2%;
  height: 100%;
}

.mypage-form {
  margin-top: 2%;
  display: inline-block;
  text-align: left;
  width: 60%;
}

.password-change-form {
  margin-top: 12%;
}

.reservation-form {
  text-align: left;
  margin-bottom: 9%;
}

.scrollable {
  max-height: 200px; /* 원하는 최대 높이 */
  overflow-y: auto; /* 스크롤 기능 활성화 */
}

.scrollable::-webkit-scrollbar {
  display: none; /* Chrome, Safari에서 스크롤바 숨기기 */
}

.scrollable {
  -ms-overflow-style: none; /* Internet Explorer 및 Edge에서 스크롤바 숨기기 */
  scrollbar-width: none; /* Firefox에서 스크롤바 숨기기 */
}

.payment-info span {
  text-align : left;
  width: 30%;
  white-space: nowrap;
  margin-right: 10px;
}

.profile-bar {
  background-color: rgba(0, 0, 0, 0.50);
  width: 11%;
}

.delete-account {
  padding: 2%;
  text-align: left;
  margin-left: 0;
}

.my-page-info {
  padding: 2%;
  text-align: left;
  margin-left: 0;
}

.small-datepicker {
  width: 120px; 
  font-size: 0.875rem;
}

.card .p-button {
  width: 25%;
  margin: 0%;
  color: white;  
}

.chartjs-tooltip {
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: #fff !important;
  border-color: #fff !important;
  border-width: 1px !important;
  padding: 5px 10px !important; 
  font-size: 12px !important; 
  max-width: 150px !important; 
  white-space: normal !important; 
  border-radius: 3px !important; 
  text-align: left !important;
}






/* 현우님 css 추가 */
.gallery-page {
  text-align: left;
  padding: 20px;
}

.carousel-container {
  margin-bottom: 40px; 
}

.carousel-title {
  padding: 0 0 0 2%;
  font-size: 24px;
  color: #000000;
  font-family: "Pretendard Light";
  src: url("../assets/font/Pretendard-1.3.9/web/static/woff2/Pretendard-Light.woff2") format("woff2"),
      url("../assets/font/Pretendard-1.3.9/web/static/woff/Pretendard-Light.woff") format("woff");
  font-weight: bold;
}

.carousel-sub-title {
  padding: 0 0 0 2%;
  font-size: 24px;
  color: #ADB5BD;
  font-family: "Pretendard Light";
  src: url("../assets/font/Pretendard-1.3.9/web/static/woff2/Pretendard-Light.woff2") format("woff2"),
      url("../assets/font/Pretendard-1.3.9/web/static/woff/Pretendard-Light.woff") format("woff");
  font-weight: bold;
  margin-bottom: 2%;
}

.ccarousel-item {
  position: relative;
  height: 500px; 
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ccarousel-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.sub-title {
  font-size: 1.2em;
  /* color: #555;  */
  text-align: center;
  /* margin-bottom: 10px; */
}

.main-title {
  font-size: 2.5em;
  color: #333; 
  text-align: center;
  /* margin-bottom: 20px; */
}

.carousel-container {
  margin-bottom: 40px; 
  background-color: #fff; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.carousel-title {
  font-size: 1.8em;
  color: #222; 
  text-align: left;
  font-weight: bold;
}

.customoverlay {
  background: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  color: #333;

}

.map-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.map-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.sub-title, .main-title {
  margin: 0;
  /* padding: 10px 0; */
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* 지도와 이미지 사이의 간격 */
}

.map-container {
  width: 500px; /* 지도 너비 */
  height: 500px; /* 지도 높이 */
}

.image-container {
  width: 60%; /* 이미지를 화면 가로폭에 맞게 설정 */
  max-width: 1920px; /* 이미지의 최대 너비를 설정 */
  height: auto; /* 높이는 자동으로 설정 */
  position: relative; /* 텍스트를 이미지 위에 배치하기 위해 설정 */
}

img {
  width: 100%;
  height: auto; /* 높이는 자동으로 설정 */
  display: block; /* 이미지의 아래쪽 여백 제거 */
}

.overlay-text {
  position: absolute; /* 이미지 위에 텍스트를 배치하기 위해 설정 */
  top: 50%; /* 수직 중앙에 배치 */
  left: 50%; /* 수평 중앙에 배치 */
  transform: translate(-50%, -50%); /* 중앙 정렬 */
  color: white; /* 텍스트 색상 */
  font-size: 24px; /* 텍스트 크기 */
  text-align: center; /* 텍스트 정렬 */
  background-color: r
}

.announcement-page {
  display: flex; /* 플렉스 박스 사용 */
  flex-direction: column; /* 세로 방향 정렬 */
  justify-content: center; /* 수직 중앙 정렬 */
  align-items: center; /* 수평 중앙 정렬 */
  text-align: center; /* 텍스트 중앙 정렬 */
  background-color: #ffffff; /* 배경색 */
  color: #333333; /* 텍스트 색상 */
  min-height: 100vh; /* 화면의 최소 높이를 전체 뷰포트로 설정 */
}

/* 서브타이틀 스타일링 */
.sub-title {
  font-size: 1.5rem; /* 서브타이틀 폰트 크기 */
  /*color: #007bff;  서브타이틀 색상 */
  margin-bottom: 10px; /* 서브타이틀 아래 여백 */
}

/* 메인 타이틀 스타일링 */
.main-title {
  font-size: 2.5rem; /* 메인 타이틀 폰트 크기 */
  font-weight: bold; /* 폰트 굵게 */
  color: #222222; /* 메인 타이틀 색상 */
  margin-bottom: 20px; /* 메인 타이틀 아래 여백 */
}

.element {
  margin-left: 10px;  /* 왼쪽 여백을 20px로 설정 */
  margin-right: 10px; /* 오른쪽 여백을 15px로 설정 */
}

img {
    /* margin-left: 20px;이미지의 왼쪽에 20px 여백 추가 */
   /* margin-right: 20px;이미지의 오른쪽에 20px 여백 추가 */
}

/* 이미지 컨테이너 스타일링 */
.image-container {
  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
}

/* MyComponent.css */
.image-gallery {
  display: flex; /* Flexbox 레이아웃 사용 */
  flex-direction: column; /* 세로로 정렬 */
  align-items: center; /* 중앙 정렬 */
  padding: 0 200px 0 200px; /* 패딩 */
  /* background-color: #f9f9f9; 배경색 */
}

.image-item {
  width: 100%; /* 이미지 너비 (필요에 따라 조정) */
  max-width: 1920px; /* 최대 너비 설정 */
  margin-bottom: 20px; /* 이미지 간 여백 */
  border-radius: 8px; /* 모서리 둥글게 */
  overflow: hidden; /* 이미지가 컨테이너를 넘지 않도록 설정 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 그림자 효과 */
  background-color: #fff; /* 배경색 */
  display: flex; /* Flexbox 사용 */
  justify-content: center; /* 중앙 정렬 */
  align-items: center; /* 중앙 정렬 */
}

.image-item img {
  width: 100%; /* 컨테이너에 맞게 이미지 너비 조정 */
  height: auto; /* 비율 유지 */
  display: block; /* 여백 제거 */
  transition: transform 0.3s, opacity 0.3s; /* 부드러운 트랜지션 효과 */
}

/* 호버 시 이미지 확대 효과 */
.image-item:hover img {
  transform: scale(1.05); /* 이미지 확대 */
  opacity: 0.9; /* 투명도 조정 */
}

